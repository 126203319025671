var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.is_loading,"variant":"transparent","spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v(" Добавить новость ")])],1),_c('b-card-body',[_c('validation-observer',{ref:"form"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{class:{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    },staticStyle:{"font-size":"12px"}},[_vm._v("Заголовок публикации")]),_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.form.header),callback:function ($$v) {_vm.$set(_vm.form, "header", $$v)},expression:"form.header"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('span',{class:{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    },staticStyle:{"font-size":"12px"}},[_vm._v("Заголовок публикации (en)")]),_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.form.header_en),callback:function ($$v) {_vm.$set(_vm.form, "header_en", $$v)},expression:"form.header_en"}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('span',{class:{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    },staticStyle:{"font-size":"12px"}},[_vm._v("Human Friendly URL")]),_c('b-form-input',{attrs:{"id":"slug"},model:{value:(_vm.form.slug),callback:function ($$v) {_vm.$set(_vm.form, "slug", $$v)},expression:"form.slug"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('span',{class:{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    },staticStyle:{"font-size":"12px"}},[_vm._v("Категория")]),_c('v-select',{attrs:{"options":_vm.News.categories,"label":"title","clearable":false,"placeholder":"Выбрать категории","multiple":""},model:{value:(_vm.form.categories),callback:function ($$v) {_vm.$set(_vm.form, "categories", $$v)},expression:"form.categories"}},[_c('template',{slot:"no-options"},[_vm._v(" Нет результатов поиcка ")])],2)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('span',{class:{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    },staticStyle:{"font-size":"12px"}},[_vm._v("Время на прочтение (в полном формате)")]),_c('b-form-input',{attrs:{"id":"time_read"},model:{value:(_vm.form.time_read),callback:function ($$v) {_vm.$set(_vm.form, "time_read", $$v)},expression:"form.time_read"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('span',{class:{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    },staticStyle:{"font-size":"12px"}},[_vm._v("Время на прочтение (в полном формате) (en)")]),_c('b-form-input',{attrs:{"id":"time_read"},model:{value:(_vm.form.time_read_en),callback:function ($$v) {_vm.$set(_vm.form, "time_read_en", $$v)},expression:"form.time_read_en"}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('span',{class:{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    },staticStyle:{"font-size":"12px"}},[_vm._v("Текст публикации")]),_c('quill-editor',{staticClass:"form-control font-weight-normal p-0",staticStyle:{"height":"auto"},attrs:{"id":"content","options":_vm.snowOption},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})]}}])})],1)]),_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"form-group-with-label"},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('span',{class:{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    },staticStyle:{"font-size":"12px"}},[_vm._v("Текст публикации (en)")]),_c('quill-editor',{staticClass:"form-control font-weight-normal p-0",staticStyle:{"height":"auto"},attrs:{"id":"content","options":_vm.snowOption},model:{value:(_vm.form.content_en),callback:function ($$v) {_vm.$set(_vm.form, "content_en", $$v)},expression:"form.content_en"}})]}}])})],1)]),_c('b-col',{attrs:{"md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"name":"alt image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('span',{class:{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    },staticStyle:{"font-size":"12px"}},[_vm._v("alt image")]),_c('b-form-input',{attrs:{"id":"alt","placeholder":""},model:{value:(_vm.form.media_alt),callback:function ($$v) {_vm.$set(_vm.form, "media_alt", $$v)},expression:"form.media_alt"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"name":"title image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('span',{class:{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    },staticStyle:{"font-size":"12px"}},[_vm._v("title image")]),_c('b-form-input',{attrs:{"id":"manufacturer","placeholder":""},model:{value:(_vm.form.media_title),callback:function ($$v) {_vm.$set(_vm.form, "media_title", $$v)},expression:"form.media_title"}})]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Обложка публикации ")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[(_vm.placeholder)?_c('b-media-aside',[_c('b-img',{ref:"refPreviewEl",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":_vm.placeholder ? _vm.placeholder.thumb : null,"height":"110","width":"170"}})],1):_vm._e(),_c('b-media-body',[_c('b-card-text',{staticClass:"my-50"},[_c('b-link',{attrs:{"id":"blog-image-text"}},[_vm._v(" "+_vm._s(_vm.placeholder ? _vm.placeholder.fileName : '')+" ")])],1),_c('div',{staticClass:"d-inline-block"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.preferMediaPickerAction}},[_vm._v(" Выбрать файл ")])],1)],1)],1)],1)])],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submit}},[_vm._v("Опубликовать")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
     <b-overlay
        :show="is_loading"
        variant='transparent'
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
    >
         
         <b-card no-body>
             <b-card-header>
                 <b-card-title>
                     Добавить новость
                 </b-card-title>
             </b-card-header>
             <b-card-body>
                  <validation-observer ref="form">
                    <b-form>
                        <b-row>
                            <b-col cols='12' md='6' lg='6'>
                                <b-form-group class="form-group-with-label">
                                    <validation-provider #default="{ errors }" rules="required">
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Заголовок публикации</span>                             
                                        <b-form-input
                                          id="name"
                                          v-model="form.header"
                                        />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols='12' md='6' lg='6'>
                                <b-form-group class="form-group-with-label">
                                    <validation-provider #default="{ errors }">
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Заголовок публикации (en)</span>                             
                                        <b-form-input
                                          id="name"
                                          v-model="form.header_en"
                                        />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        
                        <b-row>
                            <b-col cols='12' md='6' lg='6'>
                                <b-form-group class="form-group-with-label">
                                    <validation-provider #default="{ errors }" rules="required">
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Human Friendly URL</span>                             
                                        <b-form-input
                                          id="slug"
                                          v-model="form.slug"
                                        />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols='12' md='6' lg='6'>
                                <b-form-group class="form-group-with-label">
                                    <validation-provider #default="{ errors }" rules="required">
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Категория</span>                             
                                        <v-select
                                            :options="News.categories"
                                            v-model="form.categories"
                                            label="title"
                                            :clearable="false"
                                            placeholder="Выбрать категории"
                                            multiple
                                        >
                                              <template slot="no-options">
                                                  Нет результатов поиcка
                                              </template>
                                          </v-select>     
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols='12' md='6' lg='6'>
                                <b-form-group class="form-group-with-label">
                                    <validation-provider #default="{ errors }" rules="required">
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Время на прочтение (в полном формате)</span>                             
                                        <b-form-input
                                          id="time_read"
                                          v-model="form.time_read"
                                        />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols='12' md='6' lg='6'>
                                <b-form-group class="form-group-with-label">
                                    <validation-provider #default="{ errors }">
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Время на прочтение (в полном формате) (en)</span>                             
                                        <b-form-input
                                          id="time_read"
                                          v-model="form.time_read_en"
                                        />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        
                        <b-row>
                            <b-col cols='6'>
                                
                                <div class="form-group-with-label">
                                    <validation-provider #default="{ errors }" rules="required">
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Текст публикации</span>                             
                                        <quill-editor
                                            id="content"
                                            v-model="form.content"
                                            class='form-control font-weight-normal p-0'
                                            style='height: auto;'
                                            :options="snowOption"
                                        />
                                    </validation-provider>
                                </div>
                                
                            </b-col>

                            <b-col cols='6'>
                                
                                <div class="form-group-with-label">
                                    <validation-provider #default="{ errors }">
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Текст публикации (en)</span>                             
                                        <quill-editor
                                            id="content"
                                            v-model="form.content_en"
                                            class='form-control font-weight-normal p-0'
                                            style='height: auto;'
                                            :options="snowOption"
                                        />
                                    </validation-provider>
                                </div>
                                
                            </b-col>

                            <b-col md="6" lg="6">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="alt image"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">alt image</span>                             
                                        <b-form-input
                                          id="alt"
                                          v-model="form.media_alt"
                                          placeholder=""
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>
                           
                            <b-col md="6" lg="6">
                                <b-form-group class="form-group-with-label" >
                                    <validation-provider
                                            #default="{ errors }"
                                            name="title image"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">title image</span>                             
                                        <b-form-input
                                          id="manufacturer"
                                          v-model="form.media_title"
                                          placeholder=""
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>
                            
                            <b-col
                                cols="12"
                                class="mb-2"
                            >
                                <div class="border rounded p-2">
                                  <h4 class="mb-1">
                                    Обложка публикации
                                  </h4>
                                  <b-media
                                    no-body
                                    vertical-align="center"
                                    class="flex-column flex-md-row"
                                  >
                                    <b-media-aside v-if='placeholder'>
                                      <b-img
                                        ref="refPreviewEl"
                                        :src="placeholder ? placeholder.thumb : null "
                                        height="110"
                                        width="170"
                                        class="rounded mr-2 mb-1 mb-md-0"
                                      />
                                    </b-media-aside>
                                    <b-media-body>
                                      <b-card-text class="my-50">
                                        <b-link id="blog-image-text">
                                          {{ placeholder ? placeholder.fileName : '' }}
                                        </b-link>
                                      </b-card-text>
                                      <div class="d-inline-block">
                                          <b-button @click='preferMediaPickerAction' variant='primary'>
                                              Выбрать файл
                                          </b-button>
                                      </div>
                                    </b-media-body>
                                  </b-media>
                                </div>
                              </b-col>
                            
                        </b-row>
                        
                        <div class='d-flex justify-content-end'>
                            <!-- <b-button variant='gray' class='mr-1'>Предпросмотр</b-button> -->
                            <b-button @click='submit' variant='primary'>Опубликовать</b-button>
                        </div>
                        
                    </b-form>
                  </validation-observer>
             </b-card-body>
         </b-card> 

         
     </b-overlay>
</template>

<script>
    
    import 'quill/dist/quill.bubble.css'

    import slugify from '@sindresorhus/slugify';

    
    import User from "@/modules/user/"
    
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import vSelect from 'vue-select'
    import { quillEditor } from 'vue-quill-editor'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
   
    import { pickFile } from 'js-pick-file';

    import $utils from "@/modules/utils/"
    
    import News from "@/modules/contents/news"

    export default {

        data() {
            return {
                News,
                required,
                form: {
                    header: null,
                    header_en: null,
                    slug: null,
                    categories: [],
                    content: null,
                    content_en: null,
                    time_read: null,
                    time_read_en: null,
                    media_title: null,
                    media_alt: null
                },
                title: "",
                editor: null,
                is_loading: false,
                placeholder: null,
                snowOption: {
                    theme: 'bubble',
                    placeholder: "",
                    modules: {
                        toolbar: [
                            ['bold', 'italic', 'underline', 'strike'], 
                            [{ 'color': [] }, { 'background': [] }],
                            [{ 'font': [] }],
                            [{ 'align': [] }],
                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                            [ 'align', 'list', 'blockquote', 'code-block'] , 
                            ['link', 'image']
                        ]
                    }
                },
            }
        },
  
        methods: {
            
            
            submit( args ) {
                               
                if(!this.placeholder || this.placeholder.id < 0 ) return;    
                    
                this.$refs.form.validate().then(success => {
                    if( success ) {
                        this.$request.post("news/add", {
                            header: this.form.header,
                            header_en: this.form.header_en,
                            slug: this.form.slug,
                            categories: this.form.categories.map(item => item.id ),
                            media: this.placeholder.id,
                            content: this.form.content,
                            content_en: this.form.content_en,
                            time_read: this.form.time_read,
                            time_read_en: this.form.time_read_en,
                            media_title: this.form.media_title,
                            media_alt: this.form.media_alt,
                        }).then(() => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                  title: `Новость опубликована`,
                                  icon: 'CheckCircleIcon',
                                  variant: 'success',
                                }
                            });
                        })
                    }
                });
                
            },
            
            publish( args ) {
                
                
                this.editor.save().then( output => {

                    let blocks = output.blocks;
                    
                    
                    let headerBlocks = blocks.find( block => block.type === "header" );
                    let paragraphBlock = blocks.find( block => block.type === "paragraph" );
                    
                    if( !headerBlocks ) {
                        
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: `Ошибка публикации`,
                              text: "Текст публикации должен содержать хотя бы один заголовок",
                              icon: 'AlertCircleIcon',
                              variant: 'danger',
                            }
                        });
                     
                        return;    
                            
                    }
                    
                    if( !paragraphBlock ) {
                        
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: `Ошибка публикации`,
                              text: "Добавьте текст к пцбликации",
                              icon: 'AlertCircleIcon',
                              variant: 'danger',
                            }
                        });
                        
                        return;
                    }
                    
                    let params = {
                        header: headerBlocks.data.text,
                        description: paragraphBlock.data.text,
                        content: JSON.stringify(blocks),
                        media: this.placeholder ? this.placeholder.id : null
                    }
                    
                    this.$request.post("news/add", params ).then( rsp => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: `Новость опубликована`,
                              icon: 'CheckCircleIcon',
                              variant: 'success',
                            }
                        });
                        
                        this.$router.push({ name: 'admin-news-list' });
                            
                    });
                    
                });
                
            },
            
            getPlaceholderInnerStyle() {
                return {
                    position: "absolute",
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${this.placeholder.thumb})`,
                    backgroundSize: "contain"
                }
            },
            
            getPlaceholderOuterStyle() {
                return {
                    position: "absolute",
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0,
                    backgroundImage: `url(${this.placeholder.thumb})`,
                    backgroundSize: "cover",
                    filter: "blur(10px)",
                    overflow: "hidden",
                    transform: "scale(2,2)"
                }
            },
            
            prefereMediaDeleteAction( args ) {
                
                this.placeholder = null;
                args.stopPropagation();
            },
            
            preferMediaPickerAction( args ) {
                
                let options = { 
                    accept: '.jpg, .jpeg, .png', 
                    multiple: false 
                }
                
                pickFile(options).then(files => {
                    
                     files.forEach(file => {
                         
                        $utils.preferBeforeUploadAction( file ).then( data => {
                            
                            this.placeholder = {
                                id: -1,
                                type: data.type,
                                file: file,
                                fileName: file.name,
                                size: file.size,
                                extension: file.name.split('.').pop(),
                                duration: data.duration,
                                thumb: data.thumb,
                                placeholder: data.placeholder,
                                width: data.width,
                                height: data.height,
                                progress: {
                                    loaded: 0,
                                    total: 0
                                }
                                
                            }
                                                        
                            this.$request.transfer("media.upload", {
                                type: data.type,
                                width: data.width,
                                height: data.height
                            }, file, ( progress ) => {
                                this.placeholder.progress.total = progress.total;
                                this.placeholder.progress.loaded = progress.loaded;
                            }).then( rsp => {
                                this.$set( this.placeholder, "id", rsp.upload_id )
                            });
                            
                        }); 
                         
                         
                     });
                });
                
            },
        },
        
        beforeRouteEnter(from, to, next ) {
            
            let perms = User.self.permissions.find(item => item.registry.key === 'news' );
                        
            if( !perms ) {
                next({
                    name: 'admin-error-access-denied',
                    params: {
                        module: 'news'
                    }
                });
            }
            
            if( perms.write === false ) {
                next({
                    name: 'admin-error-access-denied',
                    params: {
                        module: 'news'
                    }
                });
            }
            
            next( true );
            
        },
        components: {
            vSelect,
            quillEditor,
            ValidationProvider,
            ValidationObserver
        },
        watch: {
            "form.header" : function( val ) {
                this.form.slug = slugify( val );
            }
        },
        mounted() {
            
        }

    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/quill.scss';

    .form-control .ql-editor {
            padding: 0;
    }
    
    .ql-tooltip {
            z-index: 9999;
    }
    
    [dir] .form-group-with-label .vs__dropdown-toggle .vs__selected {
            background-color: transparent !important;
            padding-right: 5px !important;
    }
    
</style>